import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"


const PageContainer = styled.div`
  border: 1px solid #f3f3f3;
  margin: 4rem;
  padding: 2rem;
`

const ColorfulSpan = styled.span`
  color: green;
`

const BlogPost = ({ data, pageContext }) => {
  const { contentfulBlogPost: post } = data
  const { siteUrl } = data.site.siteMetadata

  useEffect(() => { })

  return <>
    <PageContainer>
      <h2>blog post</h2>
      <p>date: <ColorfulSpan>{post.publishDate}</ColorfulSpan></p>
      <hr />
      <p>
        {post.body.body}
      </p>

      <p><a href={siteUrl}>back to home</a></p>
    </PageContainer>
  </>
}

export default BlogPost

export const assetQuery = graphql`
  query BlogQuery($id: String!) {
    contentfulBlogPost(id: {eq: $id}) {
      id
      title
      slug
      spaceId
      publishDate(fromNow: true)
      body {
        body
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        lang
      }
    }
  }
`